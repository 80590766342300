<template>
  <div class="content flex-column">
    <div class="flex-space-between">
      <div class="operation flex-center-v">
        <el-date-picker
            v-model="SearchValue.checkStartTime"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="检查开始日期"
            size="small"
            style="width:200px;margin-right:15px;"
        ></el-date-picker>
        <el-date-picker
            v-model="SearchValue.checkEndTime"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="检查截至日期"
            default-time="23:59:59"
            size="small"
            style="width:200px;margin-right:15px;"
        ></el-date-picker>
        <el-input v-model="SearchValue.location" placeholder="请输入检查位置" size="small" style="width:140px;margin-right:15px;"></el-input>
        <el-input v-model="SearchValue.departmentName" placeholder="请输入项目名称" size="small" style="width:140px;margin-right:15px;"></el-input>
        <el-input v-model="SearchValue.checkName" placeholder="请输入检查人" size="small" style="width:140px;margin-right:15px;"></el-input>
        <el-select v-model="SearchValue.securityCheckStatus" placeholder="检查结果" size="small" style="width:140px;margin-right:15px;">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
        <el-input v-model="SearchValue.repairManageName" placeholder="请输入整改人" size="small" style="width:140px;margin-right:15px;"></el-input>
      </div>
      <div class="search">
        <el-button type="primary" size="small" style="width:80px;" @click="search">查询</el-button>
      </div>
    </div>
    <div class="cont flex-1-auto">
      <el-table :data="tableData" height="100%" :header-cell-style="{background:'#f5f5f5',color:'#333333'}">
        <el-table-column align="center" type="index" label="序号" width="80" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column align="center" prop="departmentName" label="项目名称" width="200"  :show-overflow-tooltip="true"></el-table-column>
        <el-table-column align="center" label="检查位置" :show-overflow-tooltip="true">
          <template slot-scope="{ row }">
            <el-link type="primary" @click="handClick(row)">{{ row.location }}</el-link>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="ifTwoCode" label="方向" :show-overflow-tooltip="true">
          <template slot-scope="{row}">
            <span>{{ row.ifTwoCode ? '下' : '上' }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="model" label="吊篮型号" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column align="center" prop="hangingBasketTypeStr" label="吊篮类型" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column align="center" prop="securityCheckStatusStr" label="检查结果" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column align="center" prop="checkName" label="检查人" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column align="center" prop="checkTime" width="180px" label="检查日期" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column align="center" label="通知人" width="180px" :show-overflow-tooltip="true">
          <template slot-scope="{ row }">
            <span>{{ row.userNameList.join(',') }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="repairCutTime" label="整改时限" width="100px" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column align="center" prop="repairManageName" label="整改人" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column align="center" prop="repairTime" label="整改完成日期" width="180" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column align="center" prop="reviewManageName" label="复查人" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column align="center" prop="reviewTime" label="复查日期" :show-overflow-tooltip="true"></el-table-column>
      </el-table>
    </div>
    <div class="flex-center" style="padding-top:10px;">
      <el-pagination
          v-if="dataTotal > 10"
          background
          :current-page.sync="SearchValue.pageCurrent"
          :page-size="SearchValue.pageSize"
          :total="dataTotal"
          layout="total, prev, pager, next"
          @current-change="handleSizeChangeData"
      ></el-pagination>
    </div>

    <!-- 详情 -->
    <el-dialog
        title="检查详情"
        :visible.sync="isShowDetails"
        width="40%">
      <div v-if="detailsInfo" class="details">
        <div class="status">
          <div :style="{color: (detailsInfo.securityCheckStatus == 'four' || detailsInfo.securityCheckStatus == 'one' ? '#009966' : '#FF9900')}">
            {{ detailsInfo.securityCheckStatusStr }}
          </div>
          <div class="c-l-l" v-if="detailsInfo.securityCheckStatus == 'three'"><span style="color: #606266;">复查人：</span>{{ detailsInfo.checkName }}</div>
          <div class="c-l-l" v-if="detailsInfo.securityCheckStatus == 'two'"><span style="color: #606266;">维修人：</span>{{ detailsInfo.repairNameList.join(',') }}</div>
          <div class="c-l-l" v-if="detailsInfo.securityCheckStatus !== 'one'"><span style="color: #606266;">整改时限：</span>{{ detailsInfo.repairCutTime }}</div>
          <div v-if="detailsInfo.repairTime" class="c-l-l"><span style="color: #606266;">整改于：</span>{{ detailsInfo.repairTime }}</div>
          <div v-if="detailsInfo.reviewTime" class="c-l-l"><span style="color: #606266;">复查于：</span>{{ detailsInfo.reviewTime }}</div>
        </div>
        <div class="null"></div>
        <div class="object">
          <div class="strip"><span>检查人</span><div class="flex-1 c-l-l">{{ detailsInfo.checkName }}</div></div>
          <div class="strip"><span>检查时间</span><div class="flex-1 c-l-l">{{ detailsInfo.checkTime }}</div></div>
          <!--          <div class="strip" v-if="detailsInfo.securityCheckStatus !== 'one'"><span>维修事项</span><div class="flex-1 c-l-l">{{ detailsInfo.repairItems || '无' }}</div></div>-->
          <div class="strip" v-if="detailsInfo.securityCheckStatus !== 'one'">
            <span>补充事项</span><div class="flex-1 c-l-l">{{ detailsInfo.supplementItems || '无' }}</div>
          </div>
          <!-- <div class="strip" v-else><span>维修说明</span><div class="flex-1 c-l-l">{{ detailsInfo.repairInstructions || '无'}}</div></div> -->
          <div class="strip" style="background:#f2f2f2;padding-left:5px;">
            <img src="@/assets/images/add.png" alt="" srcset="" style="width:25px;margin-right:10px;"><span class="c-l-l">{{ detailsInfo.location }}</span>
          </div>
          <!--          <div class="bornone flex-wrap">-->
          <!--            <img v-for="(img, ind) in getImages(detailsInfo.scenePhotos)" :src="img" :key="ind + 'aaa'">-->
          <!--          </div>-->
          <div class="bornone flex-wrap">
            <el-image
                v-for="(img, ind) in getImages(detailsInfo.scenePhotos)"
                :preview-src-list="getPreviewImgList(ind)"
                :src="img"
                :key="ind + 'aaa'"
            ></el-image>
          </div>
        </div>
        <div v-for="(item,index) in checkList" :key="index" style="height: unset;padding:0 20px 0 0;">
          <div class="null" style="height: unset;padding: 10px 20px;"><span>{{toChinesNum(index+1)}}、{{ item.name }}</span></div>
          <div class="object">
            <div class="strip flex-space-between" v-for="(items,indexs) in item.checkDtoList" :key="indexs+'item'">
              <span style="min-width: 450px">{{indexs+1}}.{{ items.name }}</span>
              <div  class="flex-1 c-l-l flex-center" :class="items.status == '1'?'green':'red'">{{items.status == '1'?'正常':'异常'}}</div>
            </div>
          </div>
        </div>
        <div class="null"></div>
        <div v-for="(item, index) in detailsInfo.repairManageDtoList" :key="index">
          <div>
            <div class="object">
              <div class="strip"><span>维修人</span><div class="flex-1 c-l-l">{{ item.repairManageName }}</div></div>
              <div class="strip"><span>维修结果</span><div class="flex-1 c-l-l" style="color:#009966;">{{ item.repairResultStr }}</div></div>
              <div class="strip"><span>维修时间</span><div class="flex-1 c-l-l">{{ item.repairTime }}</div></div>
              <div class="strip"><span>维修说明</span><div class="flex-1 c-l-l">{{ item.repairInstructions }}</div></div>
              <div class="bornone flex-wrap">
                <img v-for="(img, ind) in getImages(item.repairPhotos)" :src="img" :key="ind + 'ccc'">
              </div>
            </div>
            <div class="null"></div>
          </div>
          <div>
            <div class="object" v-if="detailsInfo.reviewManageDtoList[index]">
              <div class="strip"><span>复查人</span><div class="flex-1 c-l-l">{{ detailsInfo.reviewManageDtoList[index].reviewManageName }}</div></div>
              <div class="strip">
                <span>复查结果</span>
                <div class="flex-1 c-l-l" :style="{color: detailsInfo.reviewManageDtoList[index].reviewResult == 'one' ? '#009966' : '#FF9900'}">
                  {{ detailsInfo.reviewManageDtoList[index].reviewResultStr }}
                </div>
              </div>
              <div class="strip"><span>复查时间</span><div class="flex-1 c-l-l">{{ detailsInfo.reviewManageDtoList[index].reviewTime }}</div></div>
              <div class="strip"><span>复查情况</span><div class="flex-1 c-l-l">{{ detailsInfo.reviewManageDtoList[index].reviewInstructions }}</div></div>
              <div class="bornone flex-wrap">
                <img v-for="(img, ind) in getImages(detailsInfo.reviewManageDtoList[index].reviewPhotos)" :src="img" :key="ind + 'ddd'">
              </div>
            </div>
            <div class="null"></div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import apis from "@/views/menus/api/security-check";
import {mapGetters} from "vuex";

export default {
  name: "InspectionRecord",

  components: {},

  props: {},

  data() {
    return {
      dataTotal: 0,
      tableData: [],
      checkList: [],
      SearchValue: {
        pageCurrent: 1,
        pageSize: 10,
        checkStartTime: '',
        departmentName: '',
        checkEndTime: '',
        location: '',
        checkName: '',
        securityCheckStatus: '',
        repairManageName: '',
        departmentCode: ''
      },
      options: [
        {value: '', label: '全部'},
        {value: 'one', label: '无需维修'},
        {value: 'two', label: '待维修'},
        {value: 'three', label: '待复查'},
        {value: 'four', label: '合格'},
      ],
      isShowDetails: false,
      detailsInfo: undefined
    }
  },

  computed: {
    ...mapGetters(['companyId', 'optionItem'])
  },

  watch: {
    companyId() {
      this.init()
    }
  },

  mounted() {
    this.init()
  },

  methods: {
    search() {
      this.SearchValue.pageCurrent = 1
      this.init().async
    },
    // 切换页码
    async handleSizeChangeData(val) {
      this.SearchValue.pageCurrent = val
      this.init().async
    },
    // 点击每行
    async handClick(row) {
      const isOperate = this.optionItem.permissionNameList.includes('security_check_view')
      if (!isOperate) return this.$message.error('请联系管理员添加查看权限')
      const data = await apis.getDetails(row.id)
      this.detailsInfo = data
      this.checkList = JSON.parse(data.checkPart)
      this.isShowDetails = true
    },
    async init() {
      if (!this.companyId) return this.init.async
      this.SearchValue.departmentCode = this.companyId
      const data = await apis.findAllList(this.SearchValue)
      this.tableData = data.list
      this.dataTotal = data.total
    },
    getImages(str) {
      if (!str) return []
      const imgs = str.split(',')
      return imgs
    },
    getPreviewImgList(index) {
      const images = this.detailsInfo.scenePhotos.split(',')
      let arr = [];
      for (let i = 0; i < images.length; i++) {
        arr.push(images[i + index]);
        if (i + index >= images.length - 1) { // 为了取后边小值（采用绝对值）
          index = 0 - (i + 1);
        }
      }
      return arr;
    },
    toChinesNum(num) {
      let changeNum = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九']
      let unit = ['', '十', '百', '千', '万']
      num = parseInt(num)
      let getWan = (temp) => {
        let strArr = temp.toString().split('').reverse()
        let newNum = ''
        let newArr = []
        strArr.forEach((item, index) => {
          newArr.unshift(item === '0' ? changeNum[item] : changeNum[item] + unit[index])
        })
        let numArr = []
        newArr.forEach((m, n) => {
          if (m !== '零') numArr.push(n)
        })
        if (newArr.length > 1) {
          newArr.forEach((m, n) => {
            if (newArr[newArr.length - 1] === '零') {
              if (n <= numArr[numArr.length - 1]) {
                newNum += m
              }
            } else {
              newNum += m
            }
          })
        } else {
          newNum = newArr[0]
        }

        return newNum
      }
      let overWan = Math.floor(num / 10000)
      let noWan = num % 10000
      if (noWan.toString().length < 4) {
        noWan = '0' + noWan
      }
      return overWan ? getWan(overWan) + '万' + getWan(noWan) : getWan(num)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
